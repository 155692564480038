/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-08 12:05:42 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-08-07 18:02:05
 */

//  练习记录详情
<template>
  <div class="Paper">
    <practice-Record-details></practice-Record-details>
  </div>
</template>

<script>
import practiceRecordDetails from "@/components/practiceRecordDetails.vue";
export default {
  data() {
    return {};
  },
  components: {
    practiceRecordDetails,
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.Paper {
  min-height: 100vh;
  background-color: #fff;
  > * {
    //禁止复制
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
}
</style>